import React, { ChangeEvent, MouseEvent } from "react";
import { IconPicker, TIcons } from "../IconPicker/IconPicker";
import { Spinner } from "../Spinner/Spinner";

interface IButton {
  border?: boolean;
  children: React.ReactNode;
  fullWidth?: boolean;
  handleClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClick?: () => void;
  icon?: TIcons;
  secondary?: boolean;
  leftAlign?: boolean;
  type?: `reset` | `submit` | `button`;
  submitting?: boolean;
  disabled?: boolean
}

export const Button = ({
  submitting,
  type = "button",
  children,
  border,
  handleClick,
  onClick,
  fullWidth,
  icon,
  secondary,
  leftAlign,
  disabled
}: IButton) => {

  const handleDisabled = () => {
    if (disabled || submitting) return true
    else return false
  }

  return (
    <button
      data-cy="button"
      type={type}
      disabled={handleDisabled()}
      onClick={handleClick || onClick}
      className={`
      ${border ? `border-2 border-theme-container-border` : null
        } ${fullWidth && `w-full`
        } p-4 text-md text-theme-primary bg-theme-background-accent rounded-md focus:outline-none relative w-full ${leftAlign
          ? `grid place-items-start px-4`
          : `grid place-items-center px-8`
        }  ${secondary && `text-theme-background-primary bg-white`} h-full`}
    >
      {submitting ? (
        <div data-cy="submitting" className="flex w-full relative">
          <div className="flex justify-center items-center h-full w-full md:block md:justify-start md:items-start md:left-0 md:bottom-1 absolute">
            <div
              className={`border-t-transparent border-solid animate-spin rounded-full ${secondary
                  ? `border-theme-background-primary`
                  : `border-theme-font-primary`
                } border-8 h-8 w-8`}
            />
          </div>
          <div className="hidden md:block w-full">
            <div>Submitting...</div>
          </div>
        </div>
      ) : (
        children
      )}
      {icon && (
        <IconPicker
          icon={icon}
          tailwind={`absolute right-4 ${secondary && `text-theme-font-inactive`
            }`}
        />
      )}
    </button>
  );
};
