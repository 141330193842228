import { NextPage } from 'next'
import { getSession, signIn, useSession } from 'next-auth/react'
import React from 'react'
import { GetServerSideProps } from 'next'
import { Button } from '../components/atoms/Button/Button'
import { IconPicker } from '../components/atoms/IconPicker/IconPicker'

const Index: NextPage = () => {
    const { data: session } = useSession()

    return (
        <div className="w-screen h-screen grid grid-cols-12 text-theme-font-primary bg-theme-background-primary relative p-8 md:p-0 lg:overflow-x-hidden">
            <div className="grid col-span-full place-items-center w-full">
                <div className="p-8 bg-theme-background-accent border-2 border-theme-container-border rounded-lg">
                    <div className="w-full space-y-8 ">
                        <div className="grid place-items-center">
                            <IconPicker icon="user" tailwind='w-16 h-16' />
                        </div>
                        <div className="grid place-items-center">
                            <h2 className="text-4xl">Welcome to Huddle</h2>
                        </div>
                        <div className="grid place-items-center">
                            <Button
                                onClick={() => signIn('azure-ad')}
                                secondary
                            >
                                {session ? 'Sign out' : 'Sign in with Microsoft'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index


export const getServerSideProps: GetServerSideProps = async (context) => {

    const { req, res } = context

    const session = await getSession({ req })

    if (session) {
        res.writeHead(302, {
            Location: '/home'
        })
        res.end()
        return { props: {} }
    }

    return {
        props: {}
    }
}

