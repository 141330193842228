import React from "react";
import {
  FiInbox,
  FiMaximize,
  FiExternalLink,
  FiTruck,
  FiDatabase,
  FiArrowUp,
  FiMinus,
  FiArrowDown,
  FiFilter,
  FiCheck,
  FiAlertTriangle,
  FiCalendar,
  FiChevronDown,
  FiChevronUp,
  FiInfo,
  FiDownload,
  FiSearch,
  FiUser,
  FiCheckCircle,
  FiBriefcase,
  FiMapPin,
  FiHome,
  FiClipboard,
  FiPlus,
  FiHeart,
  FiUsers,
  FiMenu,
  FiClock,
  FiSettings,
  FiEdit,
  FiTrash,
  FiBarChart,
  FiPenTool,
} from "react-icons/fi";
import {
  MdConstruction,
  MdSpeed,
  MdOutlineAdminPanelSettings,
} from "react-icons/md";
import { RiMarkPenLine } from "react-icons/ri";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { BiSelectMultiple, BiCalculator } from "react-icons/bi";

export type TIcons =
  | `calculator`
  | `external`
  | `maximise`
  | `inbox`
  | `truck`
  | `database`
  | `minus`
  | `tableArrowDown`
  | `tableArrowUp`
  | `select`
  | `filter`
  | `check`
  | `speed`
  | `alert`
  | `calendar`
  | `office`
  | `upArrow`
  | `downArrow`
  | `info`
  | `download`
  | `home`
  | `engineer`
  | `map`
  | `briefcase`
  | `search`
  | `plus`
  | `heart`
  | `user`
  | `menu`
  | `clock`
  | `clipboard`
  | `cog`
  | `edit`
  | `trash`
  | `barChart`
  | `admin`
  | `penTool`;

interface IconPickerProps {
  icon: TIcons;
  tailwind?: string;
  navBar?: boolean;
}

export const IconPicker = ({
  icon,
  tailwind,
  navBar = false,
}: IconPickerProps) => {
  const IconPickerMap = {
    icons: {
      home: FiHome,
      search: FiSearch,
      plus: FiPlus,
      heart: FiHeart,
      user: FiUser,
      menu: FiMenu,
      clock: FiClock,
      clipboard: FiClipboard,
      engineer: MdConstruction,
      cog: FiSettings,
      edit: FiEdit,
      trash: FiTrash,
      barChart: FiBarChart,
      penTool: RiMarkPenLine,
      map: FiMapPin,
      briefcase: FiBriefcase,
      info: FiInfo,
      downArrow: FiChevronDown,
      upArrow: FiChevronUp,
      office: HiOutlineOfficeBuilding,
      calendar: FiCalendar,
      alert: FiAlertTriangle,
      speed: MdSpeed,
      check: FiCheck,
      filter: FiFilter,
      select: BiSelectMultiple,
      tableArrowUp: FiArrowUp,
      tableArrowDown: FiArrowDown,
      minus: FiMinus,
      database: FiDatabase,
      truck: FiTruck,
      inbox: FiInbox,
      maximise: FiMaximize,
      external: FiExternalLink,
      calculator: BiCalculator,
      download: FiDownload,
      admin: MdOutlineAdminPanelSettings,
    },
  };

  const ResolvedIcon = IconPickerMap.icons[icon];

  if (!ResolvedIcon) return null;

  return (
    <ResolvedIcon
      data-cy="icon"
      data-testid="icon"
      className={`${navBar ? `h-6 w-6` : `h-6 w-6`} ${tailwind} shrink-0`}
    />
  );
};
